/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Analytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getMessaging, Messaging, onMessage } from "firebase/messaging";

const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const firebaseDatabaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const firebaseMessagingSenderId =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const analytics: Analytics | null = null;
let app: FirebaseApp | null = null;
let messaging: Messaging | null = null;

if (
  firebaseApiKey &&
  firebaseAuthDomain &&
  firebaseProjectId &&
  firebaseStorageBucket &&
  firebaseMessagingSenderId &&
  firebaseAppId
) {
  // app's Firebase configuration
  const firebaseConfig = {
    apiKey: firebaseApiKey,
    authDomain: firebaseAuthDomain,
    ...(firebaseDatabaseUrl && { databaseURL: firebaseDatabaseUrl }),
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
    measurementId: firebaseMeasurementId,
  };

  if (__DEV__) {
    console.log("Firebase Config: ", firebaseConfig);
  }

  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  messaging = getMessaging(app);
  onMessage(messaging, (payload) => {
    console.log("Foreground Message received. ", payload);
  });
}

export const firebaseApp = app;
export const firebaseAnalytics = analytics;
export const firebaseMessaging = messaging;
