/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StorageHelper, useConfigurationSelector } from "@bms/common";
import { getToken } from "firebase/messaging";
import { useEffect, useState } from "react";

import { firebaseMessaging } from "config/firebase";

const firebaseMessagingPublicKey =
  process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_PUBLIC_KEY;

export const useFirebaseNotifications = () => {
  const configuration = useConfigurationSelector();
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  useEffect(() => {
    const registerServiceWorker = async (): Promise<void> => {
      try {
        if ("serviceWorker" in navigator) {
          const registration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js",
          );
          if (__DEV__) {
            console.log(
              "[Firebase Notification] Service Worker registered with scope:",
              registration.scope,
            );
          }
          setRegistration(registration);
        }
      } catch (error) {
        console.error(
          "[Firebase Notification] Service Worker registration failed:",
          error,
        );
      }
    };

    registerServiceWorker();
  }, []);

  useEffect(() => {
    const isNotificationEnabled =
      !!configuration?.Application?.Notification?.Enabled &&
      !!firebaseMessagingPublicKey &&
      !!firebaseMessaging;

    const isServiceWorkerRegistered = !!registration;

    if (!isNotificationEnabled || !isServiceWorkerRegistered) {
      return;
    }

    const requestAndSaveToken = async (
      registration: ServiceWorkerRegistration | null,
    ) => {
      try {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          return;
        }

        if (!registration) {
          return;
        }

        await navigator.serviceWorker.ready;
        const currentToken = await getToken(firebaseMessaging!, {
          vapidKey: firebaseMessagingPublicKey,
          serviceWorkerRegistration: registration,
        });

        if (currentToken) {
          StorageHelper.setFirebaseMessagingToken(currentToken);
        }
      } catch (error) {
        console.error(
          "[Firebase Notification] An error occurred while retrieving token",
          error,
        );
      }
    };

    const processNotifications = async () => {
      await requestAndSaveToken(registration);
    };

    processNotifications();
  }, [configuration, registration]);
};
